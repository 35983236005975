import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as styles from './NewArticleBar.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import CardListSlider from '@components/CardList/CardList.Slider'

const NewArticleBar = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(!isVisible)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [isVisible])

  const data = useStaticQuery(graphql`
    query MyQuery {
      newsFlashAndItsArticles: allContentfulFlashNews {
        edges {
          node {
            id
            title
            images {
              gatsbyImageData
            }
            articles {
              date
              id
              slug
              title
              heroImage {
                gatsbyImageData
              }
              author {
                id
                name
                createdAt
                title
              }
              category {
                name
                id
              }
            }
            description {
              raw
            }
          }
        }
      }
    }
  `)
  
  // Check if data is empty
  if (
    !data ||
    !data.newsFlashAndItsArticles ||
    !data.newsFlashAndItsArticles.edges.length
  ) {
    return null // or return <div>Error: No data found</div>;
  }

  const sortedEdges = data.newsFlashAndItsArticles.edges.sort((a, b) => {
    const aDate = new Date(a.node.articles[0].date)
    const bDate = new Date(b.node.articles[0].date)
    return bDate - aDate
  })

  const firstEdge = sortedEdges[0]
  const node = firstEdge.node
  node.articles.sort((a, b) => new Date(b.date) - new Date(a.date))

  const descriptionRaw = node.description.raw
  const parsedDescription = JSON.parse(descriptionRaw)
  const descriptionValue = parsedDescription.content[0].content[0].value
  
  return (
    <>
      <div className={styles.container} key={node.id}>
        <div className={styles.article}>
          <CardListSlider
            columns={[1]}
            fade={true}
            dots={false}
            arrows={false}
            autoPlay={true}
            autoplaySpeed={3000}
            controlPosition="over"
          >
            {node.images.map((image, index) => (
              <GatsbyImage
                key={index}
                image={image.gatsbyImageData}
                alt=""
                className={styles.img}
                objectFit="contain"
              />
            ))}
          </CardListSlider>
          <div className={styles.blinkingnews}>
            <p
              className={styles.blink}
              style={{
                width: '11px',
                height: '11px',
                backgroundColor: 'red',
                borderRadius: '50%',
                animation: `${isVisible ? 'blink 1s infinite' : ''}`
              }}
            ></p>
            <p className={styles.live}></p>
          </div>
          <h1 className={styles.title}>{node.title}</h1>
          <p className={styles.description}>{descriptionValue}</p>
          <p className={styles.articleText}></p>
          <p className={styles.author}>{node.articles[0].author.name}</p>
          {node.articles &&
            node.articles.map((article, articleIndex) => (
              <div
                key={`${article.id}-${articleIndex}`}
                className={styles.articles}
              >
                <Link to={article.slug}>
                  <h1 className={styles.titles}>{article.title}</h1>
                  <p className={styles.articleText}></p>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default NewArticleBar
